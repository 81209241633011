import {useState, useEffect, useRef, useCallback} from 'react';
import type {EventCallback} from '@rive-app/react-canvas';

import {useSiteData} from '@/hooks/useSiteData';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import {useMediaQuery} from '@/hooks/useMediaQuery';

import Card, {type CardProps} from '../Card/Card';
import RiveAnimation, {
  type RiveAnimationProps,
} from '../RiveAnimation/RiveAnimation';

import {getFormattedDateTime} from './utils';

export interface MobileCardProps extends CardProps {
  rive: RiveAnimationProps;
  chachingAudioUrl?: string;
  chachingAudioVolume?: number;
}

export default function MobileCard({
  chachingAudioUrl,
  chachingAudioVolume = 0.25,
  rive,
  ...cardProps
}: MobileCardProps) {
  const {site} = useSiteData();
  const [isIntersecting, setIsIntersecting] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const {date: initialDate, time: initialTime} = getFormattedDateTime();
  const [date, setDate] = useState(initialDate);
  const [time, setTime] = useState(initialTime);
  const [dateLocalized, setDateLocalized] = useState(false);
  const riveAnimationPropsRef = useRef<RiveAnimationProps | null>(null);
  const [notificationTime, setNotificationTime] = useState(initialTime);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const isMobile = useMediaQuery('(max-width: 767px)');

  // How often to update the date/time on the phone
  const updateTimeInterval = 60000;

  useIntersectionObserver(cardRef, (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry: IntersectionObserverEntry) =>
      setIsIntersecting(entry.isIntersecting),
    );
  });

  // On load, check if the date needs to be localized, and if so, update
  useEffect(() => {
    // Don't update time for US or languages that our Rive font doesn't support
    if (
      site.locale !== 'en-US' &&
      site.locale !== 'zh-CN' &&
      site.locale !== 'ko' &&
      site.locale !== 'zh-TW'
    ) {
      const locDate = getFormattedDateTime(site.locale).date;
      const locTime = getFormattedDateTime(site.locale).time;
      setDate(locDate);
      setTime(locTime);
      setNotificationTime(locTime);
    }

    setDateLocalized(true);
  }, [site.locale]);

  // Set the phone date/time every minute after localizing
  useEffect(() => {
    if (!dateLocalized) {
      return;
    }
    let dateTimeInterval: NodeJS.Timeout | undefined;

    if (isIntersecting) {
      dateTimeInterval = setInterval(() => {
        const {date: newDate, time: newTime} = getFormattedDateTime(
          site.locale,
        );
        setDate(newDate);
        setTime(newTime);
      }, updateTimeInterval);
    } else if (dateTimeInterval) {
      clearInterval(dateTimeInterval);
    }

    return () => clearInterval(dateTimeInterval);
  }, [isIntersecting, dateLocalized, site.locale]);

  const handleStateChange: EventCallback = useCallback(
    (event) => {
      if (event && Array.isArray(event.data) && event.data.length > 0) {
        switch (event.data[0]) {
          case 'Zoom In':
            if (isMobile) return;

            // Play cha-ching sound
            if (!audioRef.current) {
              audioRef.current = new Audio(chachingAudioUrl);
            }
            audioRef.current.volume = chachingAudioVolume;
            audioRef.current.play();
            break;
          case 'Intro':
            // Update date and time
            const {date: newDate, time: newTime} = getFormattedDateTime();
            setDate(newDate);
            setTime(newTime);
            setNotificationTime(newTime);
            break;
        }
      }
    },
    [chachingAudioUrl, chachingAudioVolume, isMobile],
  );

  // Once localizing is done, set the Rive animation props
  useEffect(() => {
    if (!dateLocalized) return;
    riveAnimationPropsRef.current = {
      ...rive,
      onStateChange: handleStateChange,
      textRunMap: {
        appTime: time,
        bigDateText: date,
        bigTimeText: time,
        notiText1: notificationTime,
        notiText2: notificationTime,
        notiText3: notificationTime,
        notiText4: notificationTime,
      },
    } as RiveAnimationProps;
  }, [dateLocalized, date, time, notificationTime, handleStateChange, rive]);

  return (
    <Card {...cardProps} ref={cardRef}>
      <div className="absolute size-full sm:static">
        {riveAnimationPropsRef.current && (
          <RiveAnimation
            {...riveAnimationPropsRef.current}
            containerClassName="size-full mx-auto"
            posterImage={{
              ...riveAnimationPropsRef.current.posterImage,
              src: riveAnimationPropsRef.current.posterImage?.src || '',
              sizes: '(min-width: 900px) 50vw, 100vw',
            }}
          />
        )}
      </div>
    </Card>
  );
}
