/**
 * Get formatted date and time strings for use
 * within an animation depicting a mobile device
 */
export function getFormattedDateTime(locale = 'en-US') {
  const sourceDate = new Date();

  const date = sourceDate.toLocaleString(locale, {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  });

  const time = sourceDate
    .toLocaleString('en-us', {
      hour: 'numeric',
      minute: '2-digit',
    })
    .split(' ')[0];

  return {
    date,
    time,
  };
}
